import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  INDICATOR_STATUSES,
  RANGE_COLORS_PAST,
  STATUS_CLOSED,
  STATUS_NOT_RATED,
  STATUS_RATED,
} from "../../../constants/ratingSystem";
import { GROUPING_TYPE_CATEGORY, GROUPING_TYPE_INDICATOR } from "../../../constants/sustainabilitySystem";
import { getMissingStatusesSearchConfig, getNodeChangedFields, isKOFailed } from "../../../helpers/ratingSystem";
import { sortGroupings } from "../../../helpers/sustainabilitySystem";
import { useUser } from "../../../hooks/auth";
import { setSelectedRow } from "../../../hooks/ratingSystem";
import { showError, showWarning } from "../../../hooks/toast";
import { useEvent } from "../../../hooks/utils/useEvent";
import { CategorySVG, IndicatorSVG } from "../../../svg";
import {
  forEachRecursive,
  isExcludedElement,
  onlyDecimals,
  processMessage,
  roundDecimal,
  setupIndicatorStatus,
} from "../../../utils";
import {
  conditionIsCriterion,
  conditionIsIndicator,
  conditionIsIndicatorIncluded,
  conditionNotCategory,
  conditionNotIndicator,
} from "../../../validation/sustainabilitySystem";
import { AdvancedTable } from "../../AdvancedTable/AdvancedTable";
import {
  COLUMN_TYPE_CHECKBOX,
  COLUMN_TYPE_NUMBER,
  COLUMN_TYPE_PERCENT,
  COLUMN_TYPE_TEXT,
} from "../../AdvancedTable/AdvancedTableCell";
import { ProgressSquare } from "../../ProgressSquare/ProgressSquare";
import { SearchBadge } from "../../SearchBadge/SearchBadge";
import { StatusBadge } from "../../StatusBadge/StatusBadge";
import { headerStatusTemplate } from "./RatingSystemGrouping";
import "./RatingSystemGrouping.scss";
import { PASTRatingSystemStatusIndicator, RatingSystemStatusIndicatorPopover } from "./RatingSystemStatusIndicator";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { v4 as getUUID } from "uuid";

const actionCondition = (row) => row?.evaluationSystemScore !== row?.maxSystemScore;

export const RatingSystemGroupingPAST = React.memo((props) => {
  const { values, previousValues, onChange, readOnly, submitted, projectId, selectedRow, score, ratingSystemId } =
    props;
  const { t } = useTranslation();
  const [search, setSearch] = useState({});
  const [nodesByUUID, setNodesByUUID] = useState({});
  const [popoverTarget, setPopoverTarget] = useState(null);
  const [reset, setReset] = useState(false);
  const { user, tenant } = useUser();
  const storagePath = "hiddenColumns." + user?.id + "." + tenant?.id + "." + ratingSystemId;
  const hiddenColumnsString = localStorage.getItem(storagePath);
  let storedHiddenColumns = [];
  if (hiddenColumnsString) {
    try {
      storedHiddenColumns = JSON.parse(hiddenColumnsString);
    } catch (e) {
      console.error(e);
    }
  }
  const updateHiddenColumns = (hiddenColumnsUpdate) => {
    setHiddenColumns(hiddenColumnsUpdate);
    localStorage.setItem(storagePath, JSON.stringify(hiddenColumnsUpdate));
  };
  const [hiddenColumns, setHiddenColumns] = useState(storedHiddenColumns);

  useEffect(() => {
    const nodesByUUIDInner = {};
    if (previousValues) {
      forEachRecursive(previousValues, (node) => {
        nodesByUUIDInner[node.uuid] = node;
      });
    }
    setNodesByUUID(nodesByUUIDInner);
  }, [previousValues]);

  const hasExcludable = useMemo(() => {
    let res = false;
    forEachRecursive(values, (node) => {
      if (node.excludable) {
        res = true;
        return true;
      }
    });
    return res;
  }, [values]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (hasExcludable) {
      setReset(true);
    }
  }, [hasExcludable]);

  const selectStatus = (event) => {
    event.stopPropagation();
    changeAllStatuses(event.target.value);
  };

  const selectChildrenStatus = (event, parentUuid) => {
    event.stopPropagation();
    changeChildrenStatuses(parentUuid, event.target.value);
  };

  const getValues = useEvent(() => values);

  const passThrough = useMemo(
    () => ({
      submitted,
      readOnly,
      nodesByUUID,
      projectId,
      score,
      getValues,
      selectStatus,
      t,
      selectChildrenStatus,
    }),
    [submitted, readOnly, nodesByUUID, projectId, score]
  );

  const columns = useMemo(() => {
    const columnsInner = [
      {
        field: "systemReference",
        headerText: t("grouping.systemReference"),
        type: COLUMN_TYPE_TEXT,
        readOnly: true,
        width: 110,
        minWidth: 110,
        search: true,
      },
      {
        field: "name",
        headerText: t("grouping.groupingTitle"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionNotIndicator,
        readOnly: true,
        getIcon: (row) => (row.type === GROUPING_TYPE_CATEGORY ? <CategorySVG /> : null),
        groupingColumn: true,
        minWidth: 200,
        width: 250,
        search: true,
        template: ({ name, customName }) => (
          <>
            {customName || name}
            {customName && name !== customName && (
              <Tooltip title={t("grouping.customNameTooltip") + name}>
                <InfoOutlinedIcon className="custom-name-tooltip" />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        field: "indicatorName",
        headerText: t("grouping.indicatorName"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        showCondition: conditionIsIndicator,
        startAdornment: <IndicatorSVG />,
        readOnly: true,
        minWidth: 150,
        width: 200,
        search: true,
        template: ({ indicatorName, customName }) => (
          <>
            {customName || indicatorName}
            {customName && indicatorName !== customName && (
              <Tooltip title={t("grouping.customNameTooltip") + indicatorName}>
                <InfoOutlinedIcon className="custom-name-tooltip" />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        field: "indicatorStatus",
        headerText: t("grouping.status.title"),
        headerTemplate: headerStatusTemplate,
        template: (row) => {
          if (conditionIsIndicatorIncluded(row)) {
            return (
              <div className="flex-row">
                <PASTRatingSystemStatusIndicator row={row} setPopoverTarget={setPopoverTarget} />
                <StatusBadge className={"status-" + (row.indicatorStatus || STATUS_NOT_RATED)}>
                  {t("grouping.status." + (row.indicatorStatus || STATUS_NOT_RATED))}
                </StatusBadge>
              </div>
            );
          }
        },
        editTemplate: (
          row,
          column,
          updateValue,
          submittedEditTemplate,
          { selectChildrenStatus: selectChildrenStatusEditTemplate }
        ) => {
          if (row.excludedFromCalculation) {
            return null;
          }
          if (conditionIsIndicator(row)) {
            return (
              <div className="flex-row">
                <PASTRatingSystemStatusIndicator row={row} setPopoverTarget={setPopoverTarget} />
                <Select
                  fullWidth
                  value={row.indicatorStatus || STATUS_NOT_RATED}
                  className="indicator-status-select"
                  onChange={(event) => {
                    event.stopPropagation();
                    const status = event.target.value;
                    if (typeof status === "string" && (status === STATUS_RATED || status === STATUS_CLOSED)) {
                      if ((!row.evaluationSystemScore && row.evaluationSystemScore !== 0) || !row.reason) {
                        showError(t("ratingSystem.errorStatusChange"));
                        return;
                      }
                    }
                    updateValue(row.UID, "indicatorStatus", status);
                  }}
                >
                  {INDICATOR_STATUSES.map((item) => (
                    <MenuItem key={item} value={item} onClick={(event) => event.stopPropagation()}>
                      <StatusBadge className={"status-" + item}>{t("grouping.status." + item)}</StatusBadge>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            );
          } else if (row.indicatorElements.length > 0) {
            return (
              <div className="column-header-addon">
                <Select
                  fullWidth
                  value={""}
                  displayEmpty
                  className="indicator-status-select"
                  onChange={(event) => {
                    selectChildrenStatusEditTemplate(event, row.uuid);
                  }}
                >
                  <MenuItem key={-1} value="" disabled>
                    {t("grouping.status.changeTo")}
                  </MenuItem>
                  {INDICATOR_STATUSES.map((item) => (
                    <MenuItem key={item} value={item} onClick={(event) => event.stopPropagation()}>
                      <StatusBadge className={"status-" + item}>{t("grouping.status." + item)}</StatusBadge>
                    </MenuItem>
                  ))}
                </Select>
              </div>
            );
          }
        },
        width: 180,
        minWidth: 180,
        search: true,
        searchConfig: getMissingStatusesSearchConfig(t, actionCondition),
      },
      {
        field: "responsible",
        headerText: t("ratingSystem.columnGroup.responsible"),
        required: true,
        type: COLUMN_TYPE_TEXT,
        readOnly: true,
        hideable: true,
        width: 200,
        minWidth: 110,
        search: true,
      },
      {
        field: "weightingFactor",
        headerText: t("grouping.pastWeight"),
        readOnly: true,
        allowEmpty: true,
        type: COLUMN_TYPE_NUMBER,
        width: 175,
        minWidth: 175,
        columnGroup: "current",
      },
      {
        field: "evaluationSystemScore",
        headerText: t("ratingSystem.indicator.pastEvaluationSystemScore"),
        type: COLUMN_TYPE_NUMBER,
        readOnly: true,
        allowEmpty: true,
        className: (row, index, { nodesByUUID: nodesByUUIDEvaluation }) => {
          const previous = nodesByUUIDEvaluation[row.uuid];
          if (previous && row.type === GROUPING_TYPE_INDICATOR) {
            return row.maxSystemScore !== previous.maxSystemScore ? "danger" : "";
          }
          return "";
        },
        showCondition: conditionIsIndicator,
        template: (row) => (
          <div className={"text-right " + (isKOFailed(row) ? "validation-failed" : "")}>
            {row.evaluationSystemScore !== null ? roundDecimal(row.evaluationSystemScore) : "-"}
          </div>
        ),
        width: 180,
        minWidth: 180,
        columnGroup: "current",
      },
      {
        field: "progress",
        readOnly: true,
        template: (row) => {
          if (row.excludedFromCalculation) {
            return null;
          }
          if (row.type === GROUPING_TYPE_INDICATOR) {
            return (
              !!row.evaluationSystemScore && (
                <ProgressSquare
                  value={row.evaluationSystemScore}
                  rangeColors={row.maxSystemScore ? RANGE_COLORS_PAST : null}
                />
              )
            );
          } else {
            return (
              !!row.universalScore && (
                <ProgressSquare
                  value={row.universalScore}
                  rangeColors={row.maxSystemScore ? RANGE_COLORS_PAST : null}
                />
              )
            );
          }
        },
        width: 80,
        minWidth: 80,
        columnGroup: "current",
      },
      {
        field: "maxSystemScoreProportion",
        headerText: t("ratingSystem.indicator.pastMaxSystemScoreProportion"),
        required: false,
        type: COLUMN_TYPE_NUMBER,
        allowEmpty: true,
        readOnly: true,
        hideable: true,
        template: (row) =>
          row.maxSystemScoreProportion >= 0
            ? onlyDecimals(roundDecimal(row.maxSystemScoreProportion, 10), 2) + "%"
            : "",
        width: 175,
        minWidth: 175,
        columnGroup: "metrics",
      },
      {
        field: "universalScore",
        headerText: t("ratingSystem.indicator.pastUniversalScore"),
        type: COLUMN_TYPE_PERCENT,
        allowEmpty: true,
        readOnly: true,
        hideable: true,
        template: (row) =>
          !!row.universalScore && (
            <div className="text-right">{row.universalScore >= 0 ? roundDecimal(row.universalScore, 10) : ""}</div>
          ),
        width: 125,
        minWidth: 125,
        columnGroup: "metrics",
      },
      {
        field: "koValue",
        headerText: t("grouping.koValue"),
        required: false,
        type: COLUMN_TYPE_NUMBER,
        allowEmpty: true,
        readOnly: true,
        hideable: true,
        template: (row) => (
          <div className={"text-right " + (isKOFailed(row) ? "validation-failed" : "")}>{row.koValue || ""}</div>
        ),
        width: 150,
        minWidth: 150,
        columnGroup: "metrics",
      },
      !!hasExcludable && {
        field: "excluded",
        type: COLUMN_TYPE_CHECKBOX,
        headerText: t("grouping.excluded"),
        showCondition: (row) => conditionNotCategory(row) && row.excludable,
        editableCondition: conditionNotCategory,
        width: 175,
        minWidth: 175,
        isDisabled: (row) => !!row.parent.excluded,
        onChange: (UID, fieldName, value, { getValues: getValuesExcluded }) => {
          const valuesExcluded = getValuesExcluded();
          const updated = [...valuesExcluded];
          if (value) {
            const toSelectByUID = {};
            toSelectByUID[UID] = true;
            let excludedNode = null;
            forEachRecursive(updated, (node, tree, i, level, parent) => {
              if (toSelectByUID[node.UID]) {
                node.excluded = true;
                node.universalScore = 0;
                node.potentialUniversalScore = 0;
              }
              if (node.UID === UID) {
                if (conditionIsCriterion(node) || (conditionIsCriterion(parent) && isExcludedElement(parent))) {
                  excludedNode = node;
                }
              }
            });
            if (excludedNode) {
              showWarning(processMessage(t("grouping.excludedWarning"), [excludedNode.name]));
            }
            onChange(updated);
          }
        },
      },
    ];
    while (columnsInner.some((item) => !item)) {
      const index = columnsInner.findIndex((item) => !item);
      columnsInner.splice(index, 1);
    }
    return columnsInner;
  }, [hasExcludable]);

  const columnGroups = useMemo(
    () => ({
      current: {
        title: "",
        className: "column-group-current",
      },
      metrics: {
        title: t("ratingSystem.columnGroup.metrics"),
        className: "column-group-metrics",
      },
    }),
    []
  );

  const searched = useMemo(() => columns.filter((item) => !!item.search && search[item.field]), [search]);

  const getRowClassName = useCallback((row) => {
    return (
      "sustainability-row-" +
      row.type +
      (row.indicatorStatus === STATUS_CLOSED ? " status-closed" : "") +
      (row.excluded ? " excluded" : "") +
      (row.excludedFromCalculation ? " calculation-excluded" : "")
    );
  }, []);

  const changeAllStatuses = (status) => {
    const updated = [...values];
    let error = false;
    let changed = false;
    forEachRecursive(updated, (node) => {
      if (isExcludedElement(node)) {
        return null;
      }
      if (node.type === GROUPING_TYPE_INDICATOR) {
        const setupIndicatorStatusResultTemp = setupIndicatorStatus(node, status);
        if (setupIndicatorStatusResultTemp.changed) {
          changed = true;
        }
        if (setupIndicatorStatusResultTemp.error) {
          error = true;
        }
      }
    });
    if (error) {
      showError(t("ratingSystem.errorStatusChange"));
    }
    if (changed) {
      onChange(updated);
    }
  };

  const changeChildrenStatuses = (parentUuid, status) => {
    const updated = [...values];
    let error = false;
    let changed = false;
    forEachRecursive(updated, (node) => {
      if (isExcludedElement(node)) {
        return;
      }
      if (node.type === GROUPING_TYPE_INDICATOR && parentUuid === node.parentUuid) {
        const setupIndicatorStatusResultTemp = setupIndicatorStatus(node, status);
        if (setupIndicatorStatusResultTemp.changed) {
          changed = true;
        }
        if (setupIndicatorStatusResultTemp.error) {
          error = true;
        }
      }
    });
    if (error) {
      showError(t("ratingSystem.errorStatusChange"));
    }
    if (changed) {
      onChange(updated);
    }
  };

  const collapseCondition = (node) => node.excluded;
  console.log("asdf");

  if (reset) {
    return null;
  }

  console.log("qwer");
  return (
    <div className="rating-system-grouping" key={hasExcludable}>
      {!!searched.length && (
        <div className="filters">
          {searched.map((item) =>
            (typeof search[item.field].data === "object" && search[item.field].data.length > 0) ||
            (typeof search[item.field].data === "string" && search[item.field].data) ? (
              <SearchBadge
                key={getUUID()}
                field={item.headerText}
                value={search[item.field].data}
                onClose={() => setSearch({ ...search, [item.field]: undefined })}
              />
            ) : null
          )}
          <Button className="clear-filters" onClick={() => setSearch({})}>
            {t("main.clear")}
          </Button>
        </div>
      )}
      <AdvancedTable
        data={values}
        previousData={previousValues}
        collapseRowsByCondition={true}
        collapseRowCondition={collapseCondition}
        getNodeChangedFields={getNodeChangedFields}
        onChange={onChange}
        getRowClassName={getRowClassName}
        groupingField={"children"}
        onSearch={setSearch}
        search={search}
        columns={columns}
        sortRows={sortGroupings}
        onRowSelect={setSelectedRow}
        selectedRow={selectedRow}
        submitted={submitted}
        readOnly={readOnly}
        passThrough={passThrough}
        disableRowDrag
        disableHoverControls
        columnGroups={columnGroups}
        disableColumnLock
        hiddenColumns={hiddenColumns}
        setHiddenColumns={updateHiddenColumns}
      />
      <RatingSystemStatusIndicatorPopover
        setPopoverTarget={setPopoverTarget}
        popoverTarget={popoverTarget}
        actionCondition={actionCondition}
      />
    </div>
  );
});
